@import "_variables.scss";



.appbar {
  width: 100%;
  height: 65px;
  line-height: 65px;
  overflow: hidden;
  color: #000;
  &.fixed,
  &.dense,
  &.short,
  &.collapse,
  &.collapsed {
    position: sticky;
    top: 0px;
    z-index: 2;
  }
  &.dense {
    transition: top 0.4s;
  }
  &.collapse {
    transition: width 0.4s;
  }
  &.collapsed {
    width: 115px;
    border-radius: 0px 0px 30px 0px;
    .title {
      display: none;
    }
  }
  > .wrap {
    width: 95%;
    height: 100%;
    position: relative;
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
    > section {
      > .search {
        width: auto;
        height: 35px;
        overflow: hidden;
        display: inline-block;
        background-color: rgba(255, 255, 255, 0.15);
        border-radius: 5px;
        color: #fff;
        transition: background-color 0.5s;
        vertical-align: middle;
        &:hover {
          background-color: rgba(255, 255, 255, 0.3);
        }
        > i {
          font-size: 22px;
          text-indent: 20px;
          line-height: 35px;
          position: relative;
          top: -7px;
        }
        > input {
          width: 60px;
          height: 100%;
          padding: 0;
          border: none;
          float: right;
          background: transparent;
          transition: width 0.5s;
          padding-right: 8px;
          padding-left: 10px;
          color: inherit;
          &:focus {
            width: 200px;
          }
          &:hover,
          &:focus {
            outline: none;
          }
          &::placeholder {
            color: inherit;
          }
        }
      }
      > img {
        height: 40px;
        vertical-align: middle;
        padding: 8px;
      }
      > a {
        min-width: 50px;
        height: 40px;
        line-height: 40px;
        padding: 0px 6px 0px 6px;
        display: inline-block;
        text-decoration: none;
        text-transform: uppercase;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        font-family: $font;
        cursor: pointer;
        vertical-align: middle;
        &.icon {
          text-transform: none;
          width: 50px;
          height: 50px;
          padding: 0;
          margin: 0;
          text-align: center;
          border-radius: 50%;
          > i {
            font-size: 24px;
            position: relative;
            top: 0.5em;
          }
          > img {
            margin-top: 8px;
          }
        }
      }
      > .title {
        font-size: 16px;
        color: inherit;
        vertical-align: middle;
        font-family: $font;
        text-indent: 10px;
      }
    }
    > .left {
      float: left;
      height: 100%;
    }
    > .center {
      margin: 0px auto;
      height: 100%;
      &.static {
        position: absolute;
        left: 50%;
        margin-left: -50px;
        & ~ .right {
          margin-left: auto;
          margin-right: 0;
        }
      }
    }
    > .right {
      height: 100%;
      float: right;
    }
  }
}

.badge[data-badge]{
  position: relative;
  &:after{
    content: attr(data-badge);
    width: 22px;
    height: 22px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    font-weight: 600;
    font-size: 12px;
    align-content: center;
    align-items: center;
    position: absolute;
    top: -4px;
    right: -4px;
    background: #ff0026;
    color: #ffffff !important;
    border-radius: 50%;
  }
}

@import "_mixins.scss";
@import "_variables.scss";

.btn {
  margin-top: 5px;
  padding: 12px 20px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  background: color(blue);
  color: color(white);
  border: 0px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 500;
  transition: all 150ms linear;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);
  > i {
    font-size: 15px;
    vertical-align: middle;
    margin-top: -2px;
    transition: all 0.5s linear 0s;
    position: relative;
    &:nth-child(1) {
      position: relative;
      left: -5px;
    }
    &[ani],
    &[move] {
      left: 0px;
      right: 0px;
    }
  }
  &:hover {
    transition: all 150ms linear;
    opacity: 0.9;
    > i {
      &[ani="left"] {
        @include animation(ani-left 1s infinite);
        @include keyframes(ani-left) {
            10% { left: 0px;}
            50%{ left: -5px}
         };
      }
      &[ani="right"] {
        @include animation(ani-right 1s infinite);
        @include keyframes(ani-right) {
            10% { left: 0px;}
            50%{ left: 5px}
         };
      }
      &[ani="rotate"] {
        @include animation(ani-rotate 1s infinite);
        @include keyframes(ani-rotate) {
            from{ transform: rotate(0deg); }
            to{ transform: rotate(360deg); }
         };
      }

      &[move="left"] {
        @include animation(ani-left 1s 1);
      }
      &[move="right"] {
        @include animation(ani-right 1s 1);
      }
      &[move="rotate"] {
        @include animation(ani-rotate 1s 1);
      }
    }
  }
  &:active {
    transition: all 150ms linear;
    opacity: 0.7;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
  }
  &.outline {
    background: transparent;
    border: color(blue) 1px solid;
    color: #222;
    @include hoverColor(color(blue), color(grey, dark, 3));
  }
  &.text{
    background: transparent;
    box-shadow: none;
  }
  &.round,
  &.transparent{
  width: 50px;
  height: 50px;
  padding: 0px;
  margin: 0;
  text-align: center;
  border-radius: 50%;
  > i {
    font-size: 20px;
    left: 0;
    right: 0;
  }
  &:before {
    content: "";
    width: 30px;
    height: 30px;
    background: rgba(color(blue), 0.8);
    border-radius: 50%;
    margin: -6px -5.5px;
    display: inline-block;
    position: absolute;
    transform: scale(0);
    transition: all 0.5s linear 0s;
  }
}
}

.btns{
  position: relative;
  > .btn{
    &:nth-child(1){
      border-radius: 6px 0px 0px 6px;
    }
    &:nth-child(3){
      border-radius: 0px 6px 6px 0px;
    }
  }
  > span{
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    overflow: hidden;
    position: absolute;
    background: white;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-45%);
    margin-left: -17px;
    font-family: $font;
    font-size: 10px;
    z-index: 2;
  }
}

.link {
  margin-top: 5px;
  padding: 10px 15px;
  text-decoration: none;
  background: transparent;
  box-shadow: none;
  color: color(grey, dark, 3);
  font-size: 14px;
  font-weight: bold;
  line-height: 1.3;
  font-family: Tahoma, Geneva, sans-serif;
}

btn.btn[ts] {
  background: transparent;
  text-align: center;
  box-shadow: none;
  > i {
    font-size: 20px;
  }
  &:hover {
    box-shadow: none;
  }
}

@import "_mixins.scss";
@import "_variables.scss";


$modal-primary-font: "Ubuntu", sans-serif;
$modal-secondary-font: "Rubik", sans-serif;
$modal-position: 2em;


.card{
  width: 300px;
  height: auto;
  background: color(white);
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
  > img{
    width: 100%;
    clear: both;
  }
  > p{
    margin: 0;
    height: auto;
    line-height: 1.25rem;
    font-weight: 400;;
    font-family: $font;
    font-size: .875rem;
    letter-spacing: .01786em;
    padding: 1rem;
  }
  > .header{
    width: 90%;
    height: auto;
    margin: 0px auto;
    position: relative;
    padding: 1rem;
    > label{
      font-family: $font;
      font-size: 1.25rem;
      font-weight: 600;
      letter-spacing: .0125em;
      color: inherit !important;
    }
    > span{
      display: block;
      color: color(grey);
      font-family: $font;
      position: relative;
      font-size: .875rem;
      line-height: 1.375rem;
      font-weight: 500;
      letter-spacing: .00714em;
      top: 4px;
    }
  }
  > .content{
    width: 90%;
    height: auto;
    line-height: 1.25rem;
    font-weight: 400;;
    font-family: $font;
    font-size: .875rem;
    letter-spacing: .01786em;
    padding: 0 1rem 8px;
  }
  > action{
    width: 95%;
    height: auto;
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
    padding: 8px;
     button{
      padding: 8px;
      display: inline-block;
     > i{
        vertical-align: middle;
      }
      &.round{
        width: 35px;
        height: 35px;
      }
    }
  }
}

@import "_mixins.scss";
@import "_variables.scss";


$size: 40px;
$font: "Roboto", "Segoe UI", BlinkMacSystemFont, system-ui, -apple-system;
$not-active-color: rgba(0, 0, 0, 0.4);
$main-color: color(cyan, base);

.checkbox {
  z-index: 0;
  position: relative;
  display: inline-block;
  color: rgba(color(black, base), 0.87);
  font-family: $font;
  font-size: 16px;
  line-height: 1.5;
  padding-right: 15px;
  > input {
    width: $size;
    height: $size;
    position: absolute;
    top: -8px;
    left: -10px;
    display: block;
    margin: 0;
    border-radius: 50%;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    z-index: -1;
    background-color: $not-active-color;
    box-shadow: none;
    outline: none;
    opacity: 0;
    transform: scale(1);
    pointer-events: none;
    transition: opacity 0.3s, transform 0.2s;
  }
  > span {
    display: inline-block;
    width: 100%;
    cursor: pointer;
    &::before {
      content: "";
      width: 18px;
      height: 18px;
      display: inline-block;
      vertical-align: top;
      box-sizing: border-box;
      margin: 3px 11px 3px 1px;
      border: solid 2px;
      border-color: $not-active-color;
      border-radius: 2px;
      transition: border-color 0.2s, background-color 0.2s;
    }
    &::after {
      content: "";
      width: 10px;
      height: 5px;
      display: block;
      position: absolute;
      top: 3px;
      left: 1px;
      border: solid 2px transparent;
      border-right: none;
      border-top: none;
      transform: translate(3px, 4px) rotate(-45deg);
    }
  }
  > input {
    &:checked,
    &:indeterminate {
      background-color: $main-color;
      & + span::before {
        border-color: $main-color;
        background-color: $main-color;
      }
      & + span::after {
        border-color: rgb(255, 255, 255);
      }
      &:disabled + span::before{
        border-color: transparent;
        background-color: currentColor;
      }
    }
    &:indeterminate {
      & + span::after {
        border-left: none;
        transform: translate(4px, 3px);
      }
    }
  }
  &:hover{
    > input{
      opacity: 0.04;
    }
  }
  > input{
    &:focus{
      opacity: 0.12;
    }
    &:active{
      opacity: 1;
      transform: scale(0);
      transition: transform 0s, opacity 0s;
      & + span::before{
        border-color: $main-color;
      }
    }
    &:checked{
      &:active + span::before{
        border-color: transparent;
        background-color: $not-active-color;
      }
    }
    &:disabled{
      opacity: 0;
      & + span{
        color: rgba(color(black, base), 0.38);
        cursor: initial;
        &::before{
          border-color: currentColor;
        }
      }
    }
  }
  &:hover{
    > input{
      &:focus{
        opacity: 0.16;
      }
    }
  }
  &.txt-left{
    padding-right: 0px;
    > input{
      left: calc(100% - 30px);
      position: absolute;
    }
    > span{
      backgruond: red;
      &::after{
        left: auto;
        right: 6px;
      }
      &::before{
        float: right;
        position: relative;
        right: -10px;
      }
    }
  }
}

@import "_mixins.scss";
@import "_variables.scss";

$size: 22px;

@mixin closeBtnColor($color, $rgba){
    color: rgba(color($color), $rgba);
    &:hover{
      color: color($color);
    }
}
a{
  text-decoration: none;
}

.chips {
  background: rgba(color(grey), .6);
  padding: 8px;
  border-radius: 30px;
  font-size: 14px;
  font-family: $font;
  font-weight: 500;
  line-height: 1.3;
  margin-left: 5px;
  color: #2f2f2f;
  cursor: pointer;
  text-decoration: none;
  overflow: hidden;
  &:hover{
    background: color(grey);
  }
  &.raised{
     box-shadow: 0 3px 4px 0 rgba(color(black), 0.14), 0 3px 3px -2px rgba(color(black), 0.2), 0 1px 8px 0 rgba(color(black), 0.12);
  }
  &.outline{
    background: transparent;
    border: 1px solid color(grey);
    &:hover{
      background: color(grey);
      color: color(grey, dark, 3);
    }
  }
  > i {
    vertical-align: middle;
    width: $size;
    height: $size;
    font-size: $size;
    line-height: $size;
    color: color(grey, dark, 3);
    &:nth-child(1) {
      height: $size;
      margin-left: -2px;
    }
    &:nth-child(2) {
      height: $size;
      margin-right: 2px;
    }
  }
  > .close{
    font-size: $size;
    position: relative;
    top: -1.2px;
    left: 8px;
    transition: color .2s ease-in-out;
    @include closeBtnColor(color(black), .4);
  }
  > img{
    width: 33px;
    height: 33px;
    vertical-align: middle;
    margin: -2px 5px 0 -8px;
    border-radius: 50%;
    &.space{
      width: 25px;
      height: 25px;
      margin: -2px 5px 0 -3px;
      vertical-align: middle;
      border-radius: 50%;
    }
  }
}

@import "_mixins.scss";
@import "_variables.scss";

$size: 3em;
$primary-color: rgb(33, 150, 243);
$setColor: currentColor;
$transparent: transparent;
$animation-time: 0.75s;

.circular {
  width: $size;
  height: $size;
  padding: 0.25em;
  border: none;
  border-radius: 50%;
  appearance: none;
  background: $transparent;
  color: $primary-color;
  font-size: 16px;
  overflow: hidden;
  &::-webkit-progress-bar {
    background-color: $transparent;
  }
  &:indeterminate {
    -webkit-mask-image: linear-gradient($transparent 50%, black 50%),
      linear-gradient(to right, $transparent 50%, black 50%);
    mask-image: linear-gradient($transparent 50%, black 50%),
      linear-gradient(to right, $transparent 50%, black 50%);
    @include animation(circular 6s infinite cubic-bezier(0.3, 0.6, 1, 1));
    &:before,
    &::-webkit-progress-value {
      content: "";
      display: block;
      box-sizing: border-box;
      margin-bottom: 0.25em;
      border: solid 0.25em $transparent;
      border-top-color: $setColor;
      border-radius: 50%;
      width: 100% !important;
      height: 100%;
      background-color: $transparent;
      @include animation(circular-pseudo 0.75s infinite linear alternate)
    }
    &::-moz-progress-bar {
      box-sizing: border-box;
      border: solid 0.25em $transparent;
      border-top-color: $setColor;
      border-radius: 50%;
      width: 100%;
      height: 100%;
      background-color: $transparent;
      @include animation(circular-pseudo 0.75s infinite linear alternate)
    }
  }
}

.circular:indeterminate::-ms-fill {
  animation-name: -ms-ring;
}

:-ms-lang(x),
.circular:indeterminate {
  animation: none;
}

@include keyframes(circular){
  0% {
    transform: rotate(0deg);
  }
  12.5% {
    transform: rotate(180deg);
    animation-timing-function: linear;
  }
  25% {
    transform: rotate(630deg);
  }
  37.5% {
    transform: rotate(810deg);
    animation-timing-function: linear;
  }
  50% {
    transform: rotate(1260deg);
  }
  62.5% {
    transform: rotate(1440deg);
    animation-timing-function: linear;
  }
  75% {
    transform: rotate(1890deg);
  }
  87.5% {
    transform: rotate(2070deg);
    animation-timing-function: linear;
  }
  100% {
    transform: rotate(2520deg);
  }
}

@include keyframes(circular-pseudo){
  0% {
    transform: rotate(-30deg);
  }
  29.4% {
    border-left-color: $transparent;
  }
  29.41% {
    border-left-color: $setColor;
  }
  64.7% {
    border-bottom-color: $transparent;
  }
  64.71% {
    border-bottom-color: $setColor;
  }
  100% {
    border-left-color: $setColor;
    border-bottom-color: $setColor;
    transform: rotate(225deg);
  }
}

@import "_mixins.scss";
@import "_variables.scss";



@each $name, $sets in $colors {
  @each $type, $set in $sets {
    @if $type == 'base' {
      .#{$name} {
        background: $set !important;
      }
      .text-#{$name} {
        color: $set !important;
      }
    }
    @else{
      @each $key, $value in $set {
        .#{$name}-#{$type}-#{$key} {
          background: $value !important;
        }
        .text-#{$name}-#{$type}-#{$key} {
          color: $value !important;
        }
      }
    }
  }
}


@mixin colorClass($name) {
  &.outline {
    color: color($name) !important;
    background: transparent !important;
    border: color($name, base) 1px solid !important;
    &:hover {
      color: #fff !important;
      background: color($name) !important;
    }
  }

  &.text {
    background: transparent !important;
    color: color($name) !important;
  }

  &.checkbox {
    background: transparent !important;
    > input {
      &:checked {
        background: color($name) !important;
        & + span::before {
          border-color: color($name) !important;
          background: color($name) !important;
        }
      }
      &:active {
        & + span::before {
          border-color: color($name) !important;
        }
      }
    }
  }

  &.radio {
    background: transparent !important;
    > span{
      &::after{
        background-color: color($name);
      }
    }
    > input{
      &:checked {
         background-color: color($name);
         & + span::before {
           border-color: color($name);
         }
      }
    }
  }

  &.switch {
    background: transparent !important;
    > input {
      &:checked {
        background-color: color($name);
        & ~ span {
          &:before {
            background: rgba(color($name), 0.54) !important;
          }
          &:after {
            background: color($name) !important;
            border-color: color($name) !important;
          }
        }
      }
      &:active + span::before {
        background-color: rgba(color($name), 0.4);
      }
    }
  }

  &.text {
    &:hover {
      background: rgba(color($name), 0.1) !important;
    }
    > .rippleWave {
      background: rgba(color($name), 0.2) !important;
    }
  }
  &.range {
    background: color($name);
    &::-webkit-slider-thumb {
      background: color($name);
    }
  }
}

$base: ();
@each $name, $type in $colors {
  @if $name !=black {
    $base: append($base, $name);
  }
}

@include build-color-class($base);

@import "_mixins.scss";
@import "_variables.scss";


$modal-width: 320px;
$modal-height: 160px;
$modal-maxWidth: 85% !important;

$alert-modal-width: 280px;
$alert-modal-height: 128px;

$scroll-modal-width: 500px;
$scroll-modal-height: auto;

$full-modal-width: 100%;
$full-modal-height: 100vh;

$sheet-modal-width: 100%;
$sheet-modal-height: calc(100vh / 1.5);

.dialog_overlay {
  position: fixed;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1;
}
body {
  margin: 0;
  height: 100vh;
}
.loader_overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.8);
  z-index: 6;
  &.light {
    background: rgba(255, 255, 255, 0.8);
    .md-loader {
      circle {
        stroke: blue;
      }
    }
  }
}

body.dialog-open {
    overflow: hidden;
}

.dialog {
  visibility: hidden;
  position: fixed;
  top: 0px;
  bottom: 0px;
  width: 100%;
  height: 100vh;
  background: transparent;
  display: flex;
  justify-content: center;
  z-index: 3;
  pointer-events: none;
  transition: top 0s;
  > .content {
    * {
      &:focus,
      &:hover {
        outline: none;
      }
    }
    width: $modal-width;
    height: auto;
    max-width: $modal-maxWidth;
    color: black;
    background: #fff;
    line-height: normal;
    margin: auto;
    pointer-events: auto;
    border-radius: 5px;
    -webkit-box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
      0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
      0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    pointer-events: auto !important;
    overflow: hidden;
    font-family: $font;
    z-index: 2;
    > header,
    section,
    footer {
      width: calc(100% - (24 * 2px));
      margin: 0px auto;
    }
    > header {
      height: auto;
      padding-top: 20px;
      > .title {
        font-size: 18px;
        color: #444444;
        font-family: inherit;
        font-weight: bold;
        &:first-letter {
          text-transform: capitalize;
        }
      }
      > .subtitle {
        display: block;
        font-size: 14px;
      }
    }
    > section {
      height: calc(100% - 95px);
      color: #838383;
      font-size: 16px;
      overflow: hidden;
      scroll-behavior: smooth;
    }
    > .text-field {
      width: 100%;
    }
    footer {
      width: 100%;
      height: 52px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      background: #fff;
      > a {
        min-width: 30px;
        height: 35px;
        padding: 0px 8px 0px 8px;
        text-decoration: none;
        line-height: 35px;
        text-transform: uppercase;
        text-align: center;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        margin-right: 8px;
        font-family: inherit;
        font-weight: 560;
        border-radius: 5px;
        transition: all 0s !important;
        &.ripple {
          transition: all 0s;
        }
        &.left {
          justify-content: flex-start;
          position: absolute;
          margin-left: 15px;
          left: 0px;
        }
      }
    }
  }
  &.alert {
    > .content {
      width: $alert-modal-width;
      > header {
        display: none;
      }
      > section {
        height: 100%;
        padding-top: 8px;
      }
    }
  }

  &.scrollable {
    > .content {
      width: $scroll-modal-width;
      max-width: 95% !important;
      height: calc(100vh - 10%);
      position: relative;
      > header {
        height: 40px;
        > .title {
          font-weight: 500;
          font-size: 20px;
        }
      }
      > section {
        padding-left: 25px;
        padding-right: 25px;
        border-top: 1px solid #e5e5e5;
        border-bottom: 1px solid #e5e5e5;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          width: 10px;
        }

        &::-webkit-scrollbar-track {
          background: #fff;
        }

        &::-webkit-scrollbar-thumb {
          background: #ccc;
        }
      }
    }
  }

  &.full {
    > .content {
      width: $full-modal-width;
      height: $full-modal-height;
      min-width: $full-modal-width;
      visibility: visible !important;
      border-radius: 0px;
      position: fixed;
      top: none;
      bottom: -200%;
      transition: bottom 0.4s;
      > header {
        padding-top: 0;
        width: 100%;
      }
    }
     &.active {
       > .content{
         bottom: 0%;
       }
     }
  }

  &.sheet {
    > .content {
      width: $sheet-modal-width;
      height: $sheet-modal-height;
      min-width: $full-modal-width;
      visibility: visible !important;
      border-radius: 8px 8px 0px 0px;
      position: fixed;
      top: auto;
      bottom: -200%;
      transition: bottom 0.3s;
      > header {
        height: 55px;
        > .title {
          font-size: 22px;
        }
      }
      > footer {
        position: fixed;
        bottom: 0px;
      }
      > section {
        overflow-y: scroll;
        border-top: 0.5px solid #e5e5e5;
        height: calc(100% - 80px);
        padding-left: 25px;
        padding-right: 20px;
        &::-webkit-scrollbar {
          width: 10px;
        }

        &::-webkit-scrollbar-track {
          background: #fff;
        }

        &::-webkit-scrollbar-thumb {
          background: #ccc;
        }
        footer {
          position: static !important;
          > a:last-child {
            margin-right: 2px;
          }
        }
      }
    }
          &.active {
            > .content{
              bottom: 0%;
            }
      }
  }

//  &.alert,
  &.scrollable,
  &.full {
    > .content{
      footer {
        position: absolute;
        bottom: 0px;
      }
    }
  }
  &.fadeIn {
    visibility: visible !important;
    transition: all 100ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
    @include animation(fadeIn 1.5s);
  }
  &.fadeOut {
    transition: all 100ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
    @include animation(fadeOut 1s);
    visibility: hidden;
  }
  &.slideUp {
    visibility: visible !important;
    transition: all 100ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
    @include animation(slideUp 1s);
  }
  &.expandUp {
    visibility: visible !important;
    transition: all 100ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
    @include animation(expandUp 0.2s);
  }
}

@include keyframes(fadeIn) {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@include keyframes(expandUp) {
  0% {
    transform: translateY(20%) scale(0.6) scaleY(0.5);
  }
  100% {
    transform: translateY(0%) scale(1) scaleY(1);
  }
}

@import "_mixins.scss";
@import "_variables.scss";

.notrans {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

body {
  transition: margin-left .5s;
  &.overlay {
    overflow: hidden;
    &.fadeIn {
      &:after {
        visibility: visible !important;
        transition: all 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
        @include animation(fadeIn .5s);
      }
    }
    &:after {
      content: "";
      width: 100%;
      height: 140vh;
      background: rgba(0, 0, 0, 0.2);
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 1;
      visibility: hidden;
    }
  }
}
.drawer {
  width: 258px;
  height: 100vh;
  max-width: calc(100% - 56px);
  background: #fff;
  position: fixed;
  transition: left .4s;
  top: 0px;
  left: -100%;
  z-index: 2;
  border-right: 1px solid #ccc;
  > .header {
    flex-shrink: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    min-height: 64px;
    padding: 0 16px 4px;
    &.image {
      text-indent: 15px;
      display: inline-block;
    }
    > img {
      width: 45px;
      height: 45px;
      float: left;
      border-radius: 50%;
      vertical-align: middle;
    }
    > .title {
        color: rgba(#000, .87);
        font-family: $font;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-size: 1.25rem;
        line-height: 2rem;
        font-weight: 500;
        letter-spacing: .0125em;
        text-decoration: inherit;
        text-transform: inherit;
        display: block;
        margin-top: 0;
        line-height: normal;
        margin-bottom: -20px;
      &:before {
        content: "";
        display: inline-block;
        width: 0;
        height: 36px;
        vertical-align: 0;
      }
      &:after {
        content: "";
        display: inline-block;
        width: 0;
        height: 20px;
        vertical-align: -20px;
      }
    }
    > .subtitle {
        font-family: $font;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-size: .875rem;
        line-height: 1.25rem;
        font-weight: 400;
        letter-spacing: .01786em;
        text-decoration: inherit;
        text-transform: inherit;
        display: block;
        margin-top: 0;
        line-height: normal;
        margin-bottom: 0;
        color: rgba(#000, .6);
      &:before{
        content: "";
        display: inline-block;
        width: 0;
        height: 20px;
        vertical-align: 0;
      }
    }
  }
  > .content {
      width: auto;
      height: calc(100% - 55px);
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-track {
        background: #fff;
      }
      &::-webkit-scrollbar-thumb {
        background: #ccc;
      }
      h6.subheader {
      color: rgba(0,0,0,.60);
        font-family: $font;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-size: .875rem;
        line-height: 1.25rem;
        font-weight: 400;
        letter-spacing: .01786em;
        text-decoration: inherit;
        text-transform: inherit;
        display: block;
        margin-top: 0;
        line-height: normal;
        margin: 0;
        padding: 0 16px;
        &:before {
          display: inline-block;
          width: 0;
          height: 24px;
          content: "";
          vertical-align: 0;
        }
      }
    > nav.list {
        font-family: $font;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-size: 1rem;
        line-height: 1.75rem;
        font-weight: 400;
        letter-spacing: .00937em;
        text-decoration: inherit;
        text-transform: inherit;
        color: rgba(0,0,0,.60);
        margin: 0;
        padding: 8px 0;
        line-height: 1.5rem;
        list-style-type: none;
        box-shadow: none;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
        > hr {
          height: 0;
          margin: 3px 0 4px;
          border: none;
          border-bottom-width: 1px;
          border-bottom-style: solid;
          border-bottom-color: rgba(0,0,0,.12);
        }
        > a {
          color: rgba(#000, .6);
          font-family: $font;
          -moz-osx-font-smoothing: grayscale;
          -webkit-font-smoothing: antialiased;
          font-size: .875rem;
          line-height: 1.375rem;
          font-weight: 700;
          letter-spacing: .00714em;
          text-decoration: inherit;
          text-transform: inherit;
          height: 40px;
          margin: 8px;
          padding: 0 8px;
          display: flex;
          position: relative;
          -ms-flex-align: center;
          align-items: center;
          -ms-flex-pack: start;
          justify-content: flex-start;
          overflow: hidden;
          border-radius: 4px;
          &:hover {
            background: #eee;
          }
          &:first-child {
            margin-top: 2px;
          }
          &:before {
            top: -50%;
            left: -50%;
            width: 200%;
            height: 200%;
          }
          &:after {
            transition: opacity .15s linear;
          }
          > i {
            color: rgba(#000,.60);
            margin-left: 0;
            margin-right: 32px;
            width: 24px;
            height: 24px;
            font-size: 24px;
            display: -ms-inline-flexbox;
            display: inline-flex;
            -ms-flex-negative: 0;
            flex-shrink: 0;
            -ms-flex-align: center;
            align-items: center;
            -ms-flex-pack: center;
            justify-content: center;
            fill: currentColor;
          }
        }
      }
    }
  }

@include keyframes(fadeIn) {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeOut {
  transition: all 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  @include animation(fadeOut 1s);
  visibility: hidden;
}

@include keyframes(fadeOut) {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

$default-break-point: 600px;

@mixin build-grid() {
  @for $i from 1 through 100 {
    > .col-#{$i} {
      flex-basis: #{$i+"%"};
    }
  }
}

@mixin build-break-points() {
  @for $i from 1 through 10 {
    .break-#{$i} {
      @media screen and (max-width: #{$i+"00px"}) {
        flex-flow: column wrap;
        display: block;
        > * {
          flex: 0;
        }
      }
    }
  }
}

.row {
  width: 100%;
  height: auto;
  display: flex;
}

.row {
  > * {
    flex: 1 1 auto;
  }
  @include build-grid();
}

@include build-break-points();

/*Default breaking point */
@media screen and (max-width: $default-break-point) {
  .row {
    flex-flow: column wrap;
    display: block;
    > * {
      flex: 0;
    }
  }
}

@import "_mixins.scss";
@import "_variables.scss";

*:hover,
*:focus {
  outline: none;
}

$primary_color: color(indigo);

.text-field {
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  > i {
    color: #707071;
    pointer-events: none;
  }
  > .line-ripple {
    width: 100%;
    height: 2px;
    background: $primary_color;
    padding: 0;
    position: absolute;
    top: 55px;
    left: 0;
    transform: scaleX(0);
    transition: transform 0.18s cubic-bezier(0.4, 0, 0.2, 1);
  }
  > input,
  textarea {
    width: 100%;
    height: 56px;
    border: none;
    border-bottom: 2px solid #8e8e8f;
    padding: 20px 0px 4px 2px;
    vertical-align: center;
    border-radius: 4px 4px 0 0;
    outline: none;
    font-size: 16px;
    box-sizing: border-box;
    transition: background 0.5s;
    background: transparent;
      padding-left: 0px;
      &:hover, &:focus {
        background: transparent;
      }

    &:focus {
      border-bottom: none;
      & ~ .floating-label {
        color: $primary_color;
      }
      & ~ .line-ripple {
        transform: scaleX(1);
      }
    }
    &:focus,
    &:not(:placeholder-shown) {
      & ~ .floating-label {
        top: 8px;
        font-size: 12px;
        will-change: top, font-size, color;
      }
    }
  }
  > .floating-label {
    font-size: 16px;
    position: absolute;
    top: 16px;
    left: 0px;
    pointer-events: none;
    color: rgba(0, 0, 0, .54);
    transition: top 0.18s cubic-bezier(0.4, 0, 0.2, 1),
      font-size 0.18s cubic-bezier(0.4, 0, 0.2, 1);
    clear: both;
    &:first-letter {
      text-transform: capitalize;
    }
  }
  > .helper {
    font-size: 12px;
    height: 16px;
    color: #666666;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    bottom: -20px;
    left: 0px;
  }

  &.fill{
    > .floating-label {
      top: 16px;
      left: 12px;
    }
    > input{
      padding: 20px 12px 4px 12px;
       &:hover {
          background: #e5e5e5;
       }
      &:focus{
        background: #e5e5e5;
      }
    }
  }
  &.outlined {
    > .floating-label {
      transition: all 0.1s;
    }
    > input, textarea {
      padding: 20px 26px 12px 12px;
      border: none;
      height: 56px;
      border: 1.8px solid #c2c2c2;
      font-size: 16px;
      border-radius: 6px;
    }
    > input {
      &:hover {
        background: transparent;
      }
      &:focus {
        background: transparent;
        border: 2px solid $primary_color;
      }
      &:focus,
      &:not(:placeholder-shown) {
        & ~ .floating-label {
          top: -10px;
          left: 10px;
          font-size: 12px;
          will-change: top, font-size, color;
          background: #fff;
          padding: 4px;
        }
      }
    }
    > .line-ripple {
      display: none;
    }
    &.capsule {
      >.floating-label {
        left: 20px;
      }
      .lead {
        & ~.floating-label {
          left: 45px;
        }
      }
      >input {
        padding: 12px 15px;
        border-radius: 28px;
        &:focus,
        &:not(:placeholder-shown) {
          &~.floating-label {
            left: 25px;
          }
        }
      }
    }
  }
  &.fill {
    > input,
    textarea {
      background: #f5f5f6;
    }
  }
  .lead {
    position: absolute;
    top: 17px;
    left: 15px;
    & ~ input, & ~ textarea {
      padding-left: 45px !important;
    }
    & ~ .floating-label {
      left: 45px;
    }
    & ~ .helper{
      left: 20px;
    }
  }
  .trail {
    position: absolute;
    top: 18px;
    right: 15px;
    & ~ input{
      padding-left: 8px;
      padding-right: 45px !important;
    }
    & ~ .floating-label {
      left: 8px;
    }
    & ~ .helper{
      left: 10px;
    }
  }
}

@import "_mixins.scss";
@import "_variables.scss";

$primary-color: rgb(33, 150, 243);
$secondary-color: rgba(33, 150, 243, 0.12);
$setColor: currentColor;
$transparent: transparent;

.linear {
  height: 0.25em;
  color: $primary-color;
  background-color: $secondary-color;
  font-size: 16px;
  appearance: none;
  border: none;
  &::-webkit-progress-bar {
    background-color: $transparent;
  }
  &::-webkit-progress-value {
    background-color: $setColor;
    transition: all 0.2s;
  }
  &::-moz-progress-bar {
    background-color: $setColor;
    transition: all 0.2s;
  }
  &::-ms-fill {
    border: none;
    background-color: $setColor;
    transition: all 0.2s;
  }
  &:indeterminate {
    background-size: 200% 100%;
    background-image: linear-gradient(
      to right,
      $transparent 50%,
      $setColor 50%,
      $setColor 60%,
      $transparent 60%,
      $transparent 71.5%,
      $setColor 71.5%,
      $setColor 84%,
      $transparent 84%
    );
    @include animation(progress-linear 2s infinite linear);
    &::-moz-progress-bar {
      background-color: $transparent;
    }
    &::-ms-fill {
      animation-name: none;
    }
  }
}

@include keyframes(progress-linear){
  0% {
    background-size: 200% 100%;
    background-position: left -31.25% top 0%;
  }
  50% {
    background-size: 800% 100%;
    background-position: left -49% top 0%;
  }
  100% {
    background-size: 400% 100%;
    background-position: left -102% top 0%;
  }
}

@import "_mixins.scss";
@import "_variables.scss";

$light-4-grey: color(grey, light, 4);
$dark-color: color(grey, dark);
$white-color: color(white);
$black-color: color(black);

.list {
  max-width: 100%;
  height: auto;
  list-style: none;
  padding: 8px 0;
  border-radius: 4px;
  background: $white-color;
  > label {
    position: relative;
    display: block;
    color: $black-color;
    line-height: 18px;
    cursor: pointer;
  }
  > li,
  > label,
  > div{
    height: 48px;
    line-height: 48px;
    font-size: 16px;
    padding: 0 16px;
    color: $dark-color;
    font-family: $font;
    &:hover {
      background: $light-4-grey;
    }
    > a {
      text-decoration: none;
      color: $dark-color;
    }
    > div span {
      &.title {
        color: $dark-color;
      }
      &.subtitle {
        display: block;
        font-size: 14px;
        color: $dark-color;
      }
    }
    > i {
      font-size: 24px;
      vertical-align: middle;
      color: $dark-color;
      position: relative;
      margin-right: 32px;
      &.trail-icon {
        margin-right: 0;
        margin-top: 12px;
        display: inline-block;
        float: right;
        position: relative;
      }
    }
    &.two-line {
      padding: 8px 16px;
      height: 56px;
      line-height: 20px;
      display: flex;
      align-items: center;
      .lead-icon {
        margin-right: 16px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 20px;
        color: $white-color;
        background-color: $dark-color;
        text-align: center;
        float: left;
        border-radius: 50%;
      }
      > .trail-icon {
        display: inline-block;
        margin: 0 0 0 auto;
      }
      + hr {
        margin: 0;
      }
    }
    > .trail {
      float: right;
      height: 100%;
      float: right;
      position: relative;
      top: 12px;
      right: -20px;
    }
  }
  > hr {
    width: 100%;
    height: 1px;
    border: none;
    background: $dark-color;
  }
}

@import "_mixins.scss";
@import "_variables.scss";

$small-loader-width: 60px;
$small-loader-height: 60px;

$lin-small-width: 200px;

$large-loader-width: 250px;
$large-loader-height: 100px;

$base-loader-width: 150px;
$base-loader-height: 60px;

$loader-margin: 2em;

.loader {
  background: #fff;
  line-height: normal;
  position: fixed;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 5px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1), 0 5px 15px 0 rgba(0, 0, 0, 0.1);
  pointer-events: auto !important;
  visibility: hidden;
  overflow: hidden;
  > .circular {
    width: 40px;
    height: 40px;
  }
  > .linear {
    width: calc(100% - 10%);
    height: 4px;
  }
  &.small {
    width: $small-loader-width;
    height: $small-loader-height;
    display: flex;
    align-items: center;
    justify-content: center;
    &.lin {
      width: $lin-small-width;
      > .linear {
        margin-top: 4px;
      }
    }
  }
  &.large {
    width: $large-loader-width;
    height: $large-loader-height;
    font-family: $font;
    > .title {
      font-size: 16px;
      width: 100%;
      display: block;
      padding: 16px;
      box-sizing: border-box;
    }
    > .subtext {
      width: calc(100% - 60px);
      position: absolute;
      font-size: 15px;
      left: 60px;
      margin-top: 8px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    > .circular {
      width: 35px;
      height: 35px;
      float: left;
      margin-left: 15px;
    }

    &.lin {
      > .linear {
        width: 90%;
        margin-left: 5%;
      }
    }
  }
  &.base{
    width: $base-loader-width;
    height: $base-loader-height;
    font-family: $font;
    display: flex;
    align-items: center;
    justify-content: center;       
    > .title {
      width: calc(100% - 60px);
      position: absolute;
      font-size: 15px;
      left: 50px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    > .circular { 
      width: 35px;
      height: 35px;
      position: absolute;
      left: 10px;
    }
  }
  &.trans {
    background: transparent;
    box-shadow: none;
  }
  
  &.dark{
    background: #222;
    color: #fff
  }

  &.left {
    left: $loader-margin;
    right: auto;
  }
  &.right {
    right: $loader-margin;
    left: auto;
  }
  &.top {
    top: $loader-margin;
    bottom: auto;
  }
  &.bottom {
    bottom: $loader-margin;
    top: auto;
  }
  @media screen and (max-width: 400px) {
    &.left,
    &.right {
      margin: 0px auto;
      right: 0px !important;
      left: 0px !important;
    }
  }
  &.slideDownIn {
    visibility: visible !important;
    @include animation(slideDownIn 1s ease);
  }
}

@include keyframes(slideDownIn) {
  0% {
    transform: translateY(-10%);
  }
  100% {
    transform: translateY(0%);
  }
}

@import "_mixins.scss";
@import "_variables.scss";

$height: 48px;

.menu {
  width: auto;
  min-width: 120px;
  height: auto;
  -webkit-box-shadow: 0 5px 5px -3px rgba(color(black),.2), 0 8px 10px 1px rgba(color(black),.14),
  0 3px 14px 2px rgba(color(black), .12);
  box-shadow: 0 5px 5px -3px rgba(color(black),.2), 0 8px 10px 1px rgba(color(black),.14),
  0 3px 14px 2px rgba(color(black),.12);
   list-style: none;
  padding: 8px 0 8px 0;
  border-radius: 2px;
  background: color(white);
  visibility: hidden;
  position: absolute;
  top: 0px;
  z-index: 1;
  border-radius: 4px;
  > li {
    height: $height;
    line-height: $height;
    padding: 0 16px;
    > a {
      width: 100%;
      text-align: left;
      font-family: $font;
      font-size: 1rem;
      color: color(grey, dark, 3);
      text-decoration: none;
      border-radius: 0;
      > i {
        vertical-align: middle;
      }
    }
    &:hover {
      background: color(grey,light,4) !important;
    }
  }
  > hr{
    width: 100%;
    height: 1px;
    border: none;
    background: color(grey,light,1);
  }
}

/*CSS Animations*/

.pullDown{
    visibility: visible;
    animation-name: pullDown;
    -webkit-animation-name: pullDown;

    animation-duration: .15s;
    -webkit-animation-duration: .15s;

    animation-timing-function: ease-out;
    -webkit-animation-timing-function: ease-out;

    transform-origin: 50% 0%;
    -ms-transform-origin: 50% 0%;
    -webkit-transform-origin: 50% 0%;
}

@keyframes pullDown {
    0% {
        transform: scaleY(0);
    }
    100% {
        transform: scaleY(1);
    }
}

.pullUp{
    visibility: hidden;
    animation-name: pullDown;
    -webkit-animation-name: pullDown;

    animation-duration: .1s;
    -webkit-animation-duration: .1s;

    animation-timing-function: ease-out;
    -webkit-animation-timing-function: ease-out;
    animation-direction:reverse;


    transform-origin: 50% 0%;
    -ms-transform-origin: 50% 0%;
    -webkit-transform-origin: 50% 0%;
}

@import "_mixins.scss";
@import "_variables.scss";


$not-active: rgba(color(black, base), 0.4);
$primary-color: color(cyan, base);
$font: "Roboto", "Segoe UI", BlinkMacSystemFont, system-ui, -apple-system;

.radio {
  z-index: 0;
  position: relative;
  display: inline-block;
  color: rgba(color(black, base), 0.87);
  font-family: $font;
  font-size: 16px;
  line-height: 1.5;
  > input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    z-index: -1;
    position: absolute;
    left: -1px;
    top: -8px;
    display: block;
    margin: 0;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: $not-active;
    outline: none;
    opacity: 0;
    transform: scale(1);
    pointer-events: none;
    transition: opacity 0.3s, transform 0.2s;
  }
  > span {
    display: inline-block;
    width: 100%;
    cursor: pointer;
    padding-left: 10px;
    &::before {
      content: "";
      display: inline-block;
      box-sizing: border-box;
      margin: 2px 10px 2px 0;
      border: solid 2px;
      border-color: $not-active;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      vertical-align: top;
      transition: border-color 0.2s;
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 2px;
      left: 10px;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      background-color: $primary-color;
      transform: translate(5px, 5px) scale(0);
      transition: transform 0.2s;
    }
  }
  > input {
    &:checked {
      background-color: $primary-color;
      & + span::before {
        border-color: $primary-color;
      }
      & + span::after {
        transform: translate(5px, 5px) scale(1);
      }
    }
    &:focus {
      opacity: 0.12;
    }
  }
  &:hover {
    > input {
      opacity: 0.04;
      &:focus {
        opacity: 0.16;
      }
    }
  }
  /*Active*/
  > input {
    &:active {
      opacity: 1;
      transform: scale(0);
      transition: transform 0s, opacity 0s;
      & + span::before {
        border-color: $primary-color;
      }
    }
    &:disabled {
      opacity: 0;
      & + span {
        color: rgba(0, 0, 0, 0.38);
        cursor: initial;
        &::before {
          border-color: currentColor;
        }
        &::after {
          background-color: currentColor;
        }
      }
    }
  }
  &.txt-left{
    text-indent: 50xp;
    padding-right: 0px;
    > input{
      left: calc(100% - 30px);
      position: absolute;
    }
    > span{
      &::after{
        left: auto;
        right: 10px;
      }
      &::before{
        float: right;
        position: relative;
        right: 0px;
      }
    }
  }
}

@import "_mixins.scss";
@import "_variables.scss";

.ripple {
  z-index: 1;
  position: relative;
  overflow: hidden !important;
  .rippleWave {
    width: 12px;
    height: 12px;
    position: absolute;
    background: rgba(255, 255, 255, 0.3) !important;
    border-radius: 100%;
    animation: ripple 1.6s;
  }
}

@keyframes ripple {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(40);
    opacity: 0;
  }
}

.ripple {
   &.light {
     .rippleWave {
       background: rgba(255, 255, 255, 0.3) !important;
     }
   }
   &.dark {
     .rippleWave {
       background: rgba(0, 0, 0, 0.3) !important;
     }
   }
 }

*:hover,
*:focus {
  outline: none;
}

$primary_color: #6200ee;

.select-field {
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  background-repeat: no-repeat;
  font-family: "Roboto", sans-serif;
  background-position: right 8px bottom 12px;
  > i {
    color: #707071;
    pointer-events: none;
  }
  > .line-ripple {
    width: 100%;
    height: 2px;
    background: $primary_color;
    padding: 0;
    position: absolute;
    bottom: -9px;
    left: 0;
    transform: scaleX(0);
    transition: transform 0.18s cubic-bezier(0.4, 0, 0.2, 1);
  }

  > .floating-label {
    font-size: 16px;
    position: absolute;
    top: 16px;
    left: 4px;
    pointer-events: none;
    color: rgba(0, 0, 0, 0.54);
    transition: top 0.18s cubic-bezier(0.4, 0, 0.2, 1),
      font-size 0.18s cubic-bezier(0.4, 0, 0.2, 1);
    clear: both;
    &:first-letter {
      text-transform: capitalize;
    }
  }
  > select {
    padding: 20px 26px 4px 4px;
    font-family: Roboto, sans-serif;
    width: 100%;
    height: 56px;
    font-size: 16px;
    line-height: 28px;
    border: none;
    border-bottom: 1px solid;
    border-radius: 0;
    white-space: nowrap;
    cursor: pointer;
    border-radius: 4px 4px 0 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    > option {
      background: #fff;
    }
    &:focus,
    &.has-value {
      &:focus {
        & ~ .floating-label {
          color: $primary_color;
        }
        & ~ .line-ripple {
          transform: scaleX(1);
        }
      }
      & ~ .floating-label {
        top: 8px;
        font-size: 12px;
        will-change: top, font-size, color;
      }
    }
  }
  > .helper {
    font-size: 12px;
    height: 16px;
    padding: 0 12px;
    color: #666666;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    bottom: -20px;
  }
  &.fill {
    .floating-label{
      left: 12px;
    }
    > select {
      padding: 20px 26px 4px 12px;
      background: #f5f5f6;
    }
  }
  &.outlined{
    > .floating-label {
      left: 12px;
      transition: all 0.1s;
    }
    > select{
      padding: 20px 26px 12px 12px;
      border: none;
      height: 56px;
      line-height: 20px;
      background: transparent;
      border: 1.8px solid #c2c2c2;
      font-size: 16px;
      border-radius: 6px;
      &:hover {
        background: transparent;
      }
      &:focus {
        background: transparent;
        border: 2px solid $primary_color;
      }
      &.has-value{
        & ~ .floating-label {
          top: -7px;
          left: 10px;
          font-size: 12px;
          will-change: top, font-size, color;
          background: #fff;
          padding: 4px;
        }
      }
    }
  }
  .lead {
    position: absolute;
    top: 17px;
    left: 15px;
    & ~ select {
      padding-left: 45px !important;
    }
    & ~ .floating-label {
      left: 45px;
    }
  }
  .trail {
    position: absolute;
    top: 18px;
    right: 5px;
    & ~ select{
      padding-right: 45px !important;
    }
  }
}

@import '_mixins.scss';

$shadow-param-1-opacity: .2;
$shadow-param-2-opacity: .14;
$shadow-param-3-opacity: .12;
$shadow-property: box-shadow !default;
$shadow-transition-duration: 280ms !default;
$shadow-transition-timing-function: cubic-bezier(.4, 0, .2, 1) !default;

$shadow-param-1: (
  0: "0px 0px 0px 0px",
  1: "0px 2px 1px -1px",
  2: "0px 3px 1px -2px",
  3: "0px 3px 3px -2px",
  4: "0px 2px 4px -1px",
  5: "0px 3px 5px -1px",
  6: "0px 3px 5px -1px",
  7: "0px 4px 5px -2px",
  8: "0px 5px 5px -3px",
  9: "0px 5px 6px -3px",
  10: "0px 6px 6px -3px",
  11: "0px 6px 7px -4px",
  12: "0px 7px 8px -4px",
  13: "0px 7px 8px -4px",
  14: "0px 7px 9px -4px",
  15: "0px 8px 9px -5px",
  16: "0px 8px 10px -5px",
  17: "0px 8px 11px -5px",
  18: "0px 9px 11px -5px",
  19: "0px 9px 12px -6px",
  20: "0px 10px 13px -6px",
  21: "0px 10px 13px -6px",
  22: "0px 10px 14px -6px",
  23: "0px 11px 14px -7px",
  24: "0px 11px 15px -7px"
);

$shadow-param-2: (
  0: "0px 0px 0px 0px",
  1: "0px 1px 1px 0px",
  2: "0px 2px 2px 0px",
  3: "0px 3px 4px 0px",
  4: "0px 4px 5px 0px",
  5: "0px 5px 8px 0px",
  6: "0px 6px 10px 0px",
  7: "0px 7px 10px 1px",
  8: "0px 8px 10px 1px",
  9: "0px 9px 12px 1px",
  10: "0px 10px 14px 1px",
  11: "0px 11px 15px 1px",
  12: "0px 12px 17px 2px",
  13: "0px 13px 19px 2px",
  14: "0px 14px 21px 2px",
  15: "0px 15px 22px 2px",
  16: "0px 16px 24px 2px",
  17: "0px 17px 26px 2px",
  18: "0px 18px 28px 2px",
  19: "0px 19px 29px 2px",
  20: "0px 20px 31px 3px",
  21: "0px 21px 33px 3px",
  22: "0px 22px 35px 3px",
  23: "0px 23px 36px 3px",
  24: "0px 24px 38px 3px"
);

$shadow-param-3: (
  0: "0px 0px 0px 0px",
  1: "0px 1px 3px 0px",
  2: "0px 1px 5px 0px",
  3: "0px 1px 8px 0px",
  4: "0px 1px 10px 0px",
  5: "0px 1px 14px 0px",
  6: "0px 1px 18px 0px",
  7: "0px 2px 16px 1px",
  8: "0px 3px 14px 2px",
  9: "0px 3px 16px 2px",
  10: "0px 4px 18px 3px",
  11: "0px 4px 20px 3px",
  12: "0px 5px 22px 4px",
  13: "0px 5px 24px 4px",
  14: "0px 5px 26px 4px",
  15: "0px 6px 28px 5px",
  16: "0px 6px 30px 5px",
  17: "0px 6px 32px 5px",
  18: "0px 7px 34px 6px",
  19: "0px 7px 36px 6px",
  20: "0px 8px 38px 7px",
  21: "0px 8px 40px 7px",
  22: "0px 8px 42px 7px",
  23: "0px 9px 44px 8px",
  24: "0px 9px 46px 8px"
);

@for $value from 0 through 24 {
  .z-#{$value} {
    @include shadow-build($value);
  }
}

.z-anim {
  transition: shadow-transition-value();
  will-change: $shadow-property;
}

@import "_mixins.scss";
@import "_variables.scss";


$not-active-color: rgba(color(black, base), 0.4);
$main-color: color(cyan, base);
$font: "Roboto", "Segoe UI", BlinkMacSystemFont, system-ui, -apple-system;

.slider {
  display: inline-block;
  width: 200px;
  color: $not-active-color;
  font-family: $font;
  font-size: 16px;
  line-height: 1.5;
  > input {
    width: 100%;
    height: 36px;
    -webkit-appearance: none;
    display: block;
    position: relative;
    top: 24px;
    margin: 0 0 -36px;
    background-color: transparent;
    cursor: pointer;
    &:last-child {
      position: static;
      margin: 0;
    }
    &:focus {
      outline: none;
    }
    &:disabled {
      cursor: default;
      opacity: 0.38;
      & + span {
        color: rgba(0, 0, 0, 0.38);
      }
    }

    &::-webkit-slider-runnable-track {
      margin: 17px 0;
      border-radius: 1px;
      width: 100%;
      height: 2px;
      background-color: rgba($main-color, 0.24);
    }
    &::-webkit-slider-thumb {
      appearance: none;
      -webkit-appearance: none;
      border: none;
      border-radius: 50%;
      height: 2px;
      width: 2px;
      transform: scale(6, 6);
      transition: box-shadow 0.2s;
      background-color: $main-color;
    }
  }
  > span {
    display: inline-block;
    margin-bottom: 36px;
  }

  &:hover {
    > input::-webkit-slider-thumb {
      box-shadow: 0 0 0 2px rgba($main-color, 0.04);
    }
    > input:hover::-moz-range-thumb {
      box-shadow: 0 0 0 2px rgba($main-color, 0.04);
    }
    > input:focus::-moz-range-thumb {
      box-shadow: 0 0 0 2px rgba($main-color, 0.16);
    }
    > input::-ms-thumb {
      box-shadow: 0 0 0 2px rgba($main-color, 0.04);
    }
    > input:focus::-ms-thumb {
      box-shadow: 0 0 0 2px rgba($main-color, 0.16);
    }
  }
  > input {
    &:active::-webkit-slider-thumb {
      box-shadow: 0 0 0 2px rgba($main-color, 0.24) !important;
    }
    &:disabled::-webkit-slider-runnable-track {
      background-color: rgba(0, 0, 0, 0.38);
      &::-webkit-slider-thumb {
        background-color: rgb(0, 0, 0);
        color: rgb(255, 255, 255);
        box-shadow: 0 0 0 1px rgb(255, 255, 255);
        transform: scale(4, 4);
      }
    }
    &::-moz-range-track {
      margin: 17px 0;
      border-radius: 1px;
      width: 100%;
      height: 2px;
      background-color: rgba($main-color, 0.24);
    }
    &::-moz-range-thumb {
      appearance: none;
      -moz-appearance: none;
      border: none;
      border-radius: 50%;
      height: 2px;
      width: 2px;
      background-color: $main-color;
      transform: scale(6, 6);
      transition: box-shadow 0.2s;
    }
    &::-moz-range-progress {
      border-radius: 1px;
      height: 2px;
      background-color: $main-color;
    }
    &:focus::-moz-range-thumb {
      box-shadow: 0 0 0 2px rgba($main-color, 0.12);
    }
    &:active::-moz-range-thumb {
      box-shadow: 0 0 0 2px rgba($main-color, 0.24) !important;
    }
    &:disabled {
      &::-moz-range-track {
        background-color: rgba(0, 0, 0, 0.38);
      }
      &::-moz-range-progress {
        background-color: rgba(0, 0, 0, 0.87);
      }
      &::-moz-range-thumb {
        background-color: rgb(0, 0, 0);
        box-shadow: 0 0 0 1px rgb(255, 255, 255) !important;
        transform: scale(4, 4);
      }
    }
    &::-moz-focus-outer {
      border: none;
    }
    &::-ms-track {
      box-sizing: border-box;
      margin: 17px 0;
      border: none;
      border-radius: 1px;
      padding: 0 17px;
      width: 100%;
      height: 2px;
      background-color: transparent;
    }
    &::-ms-fill-lower {
      border-radius: 1px;
      height: 2px;
      background-color: $main-color;
    }
    &::-ms-fill-upper {
      border-radius: 1px;
      height: 2px;
      background-color: rgba($main-color, 0.24);
    }
    &::-ms-thumb {
      appearance: none;
      margin: 0 17px;
      border: none;
      border-radius: 50%;
      height: 2px;
      width: 2px;
      background-color: $main-color;
      transform: scale(6, 6);
      transition: box-shadow 0.2s;
    }
    &:focus {
      &::-ms-thumb {
        box-shadow: 0 0 0 2px rgba($main-color, 0.12);
      }
      &::-ms-thumb {
        box-shadow: 0 0 0 2px rgba($main-color, 0.24) !important;
      }
    }
    &:disabled {
      &::-ms-fill-lower {
        background-color: rgba(0, 0, 0, 0.38);
      }
      &::-ms-fill-upper {
        background-color: rgba(0, 0, 0, 0.38);
        opacity: 0.38;
      }
      &::-ms-thumb {
        background-color: rgb(0, 0, 0);
        box-shadow: 0 0 0 1px rgb(255, 255, 255) !important;
        transform: scale(4, 4);
      }
    }
  }
}


@import "_mixins.scss";
@import "_variables.scss";

$snackbar-width: 350px;
$action-snackbar-width: 380px;
$snackbar-height: 60px;
$snackbar-maxWidth: 95%;
$snackbar-radius: 5px;
$modal-position: .5em;

*:hover,
*:focus {
  outline: none;
}

snackbar {
  width: 100%;
  height: 100vh;
  pointer-events: none;
  > div {
    width: $snackbar-width;
    height: $snackbar-height;
    max-width: $snackbar-maxWidth;
    font-family: $font;
    background: color(grey,dark,3);
    color: color(grey,light,2);
    border-radius: $snackbar-radius;
    position: fixed;
    bottom: -10px;
    pointer-events: auto;
    overflow: hidden;
    box-shadow: 0 4px 8px 0 rgba(color(black), 0.2), 0 6px 20px 0 rgba(color(black), 0.19);
    > text {
      width: calc(100% - 90px);
      height: 60px;
      float: left;
      color: #fff;
      line-height: 60px;
      text-indent: 15px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    > button {
      display: inline-block;
      float: right;
      margin: 14px;
      height: 30px;
      margin-top: 16px;
      border: none;
      background: transparent;
      color: color(grey,light,2);
      border-radius: 5px;
      text-transform: uppercase;
      display: inline-block;
      &:nth-child(2) {
        width: 40px;
        height: 40px;
        margin-top: 10px;
        background: rgba(color(grey,dark,3), 0.1);
        border-radius: 50%;
        transition: all 0.5s linear 0s;
        > i {
          vertical-align: middle;
          font-size: 20px;
        }
      }
      &:nth-child(3) {
        font-size: 14px;
        position: relative;
        left: 20px !important;
      }
    }
  }
  &.light {
    background: rgba(color(grey,dark,3), 0.6);
    > div {
      background: color(white);
      box-shadow: 0 5px 30px 0 rgba(color(black), 0.1),
        0 5px 30px 0 rgba(color(black), 0.1);
      > text {
        color: color(grey,dark,3);
      }
      > button {
        color: color(grey,dark,3);
      }
    }
  }

  &.action {
    > div {
      width: $action-snackbar-width;
      > text {
        width: calc(100% - 160px);
      }
    }
  }
  &.top {
    > div {
      top: $modal-position;
    }
  }
  &.bottom {
    > div {
      bottom: $modal-position;
    }
  }
  &.left {
    > div {
      left: $modal-position;
    }
  }
  &.right {
    > div {
      right: $modal-position;
    }
  }
  &.center {
    > div {
      margin: 0px auto;
      left: 0px;
      right: 0px;
    }
  }
}

/*##########################

Snackbar Animations*/

.slideUpBottom{
   @include animation(slideUpBottom .5s 0s ease forwards);
}
@include keyframes(slideUpBottom) {
  from {
       bottom: -100px
    }
    to {
       bottom: $modal-position;
    }
}

.slideUpTop{
  @include animation(slideUpTop .5s 0s ease forwards);
}


@include keyframes(slideUpTop){
  from {
      top: -100px;
    }
    to {
      top: $modal-position;
    }
}

/*
==============================================
slideDown Animation
==============================================
*/

.slideDownBottom{
  @include animation(slideDownBottom .5s 0s ease forwards);
}

@include keyframes(slideDownBottom){
    from {
       bottom: $modal-position;
    }
    to {
       bottom: -100px
    }
}


.slideDownTop{
  @include animation(slideDownTop 1s 0s ease forwards)
}

@include keyframes(slideDownTop){
    from {
       top: $modal-position;
    }
    to {
       top: -100px
    }
}

@import "_mixins.scss";
@import "_variables.scss";

$not-active-color: rgba(color(black, base), 0.4);
$main-color: color(cyan, base);
$font: "Roboto", "Segoe UI", BlinkMacSystemFont, system-ui, -apple-system;

.switch {
  z-index: 0;
  position: relative;
  display: inline-block;
  color: rgba(color(black, base), 0.87);
  font-family: $font;
  font-size: 16px;
  line-height: 1.5;
  * {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  > input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    z-index: -1;
    position: absolute;
    left: -10px;
    top: -7px;
    display: block;
    margin: 0;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: $not-active-color;
    outline: none;
    opacity: 0;
    transform: scale(1);
    pointer-events: none;
    transition: opacity 0.3s 0.1s, transform 0.2s 0.1s;
  }
  > span {
    display: inline-block;
    width: 100%;
    cursor: pointer;
    padding-left: 10px;
    &::before {
      content: "";
      display: inline-block;
      margin: 6px 0 2px 0px;
      border-radius: 7px;
      width: 36px;
      height: 14px;
      position: relative;
      left: -10px;
      background-color: $not-active-color;
      vertical-align: top;
      transition: background-color 0.2s, opacity 0.2s;
    }
    &::after {
      content: "";
      position: absolute;
      top: 2px;
      left: 0px;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      background-color: color(white, base);
      box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
      transition: background-color 0.2s, transform 0.2s;
    }
  }

  > input {
    &:checked {
      left: 6px;
      background-color: $main-color;
      & + span::before {
        background-color: rgba($main-color, 0.4);
      }
      & + span::after {
        background-color: $main-color;
        transform: translateX(16px);
      }
      &:active {
        & + span::before {
          background-color: rgba(color(black, base), 0.38);
        }
      }
    }
  }
  &:hover {
    > input {
      opacity: 0.04;
      &:focus {
        opacity: 0.16;
      }
    }
  }
  > input {
    &:focus {
      opacity: 0.12;
    }
    &:active {
      opacity: 1;
      transform: scale(0);
      transition: transform 0s, opacity 0s;
    }
    & + span::before {
      background-color: rgba(color(black, base), 0.4);
    }
    &:disabled {
      opacity: 0;
      & + span {
        color: color(black, base);
        opacity: 0.38;
        cursor: default;
        &::before {
          background-color: rgba(color(black, base), 0.38);
        }
      }
    }
    &:checked {
      &:disabled + span::before {
        background-color: rgba($main-color, 0.6);
      }
    }
  }
  &.txt-left{
    padding-left: 6px;
    padding-right: 0px;
    > input{
      left: calc(100% - 45px);
      position: absolute;
      &:checked {
        left: calc(100% - 30px);
      }
    }
    > span{
      padding-right: 10px;
      padding-left: 0;
      &::after{
        left: auto;
        right: 16px;
      }
      &::before{
        float: right;
        position: relative;
        left: 0px;
        right: 0px;
      }
    }
  }
}

@import "_mixins.scss";
@import "_variables.scss";


/* Reference specs https://material.io/design/components/data-tables.html#specs */
.table {
  width: 100%;
  display: table;
  background: white;
  border-collapse: collapse;
  font-family: $font;
  border-spacing: 0;
  border-radius: 6px;
	& thead {
  border-bottom: 1px solid rgba(#000, .12);
	}
	& tr {
		height: 48px;
		border-bottom: 0;
	}
	& td, th {
		padding: 16px;
		font-size: 12px;
		color: rgba(#000, .54);
		font-weight: 700;
		display: table-cell;
		text-align: left;
		vertical-align: middle;
	}
	& td, tr {
		font-size: 13px;
		font-weight: normal;
		color: rgba(#000, .87);
	}

	&.zebra tr {
	  border-bottom: none;
	}
	&.zebra > tbody {
		> tr:nth-child(odd) {
		  background-color: rgba(#f2f2f2, .5);
		}
		> tr > td {
		  border-radius: 0;
	 	}
	}

	&.pop > tbody {
		> tr {
		  -webkit-transition: background-color .25s ease;
		  transition: background-color .25s ease;
		   &:hover {
		           background-color: rgba(#f2f2f2, .5);
			}
		}
	}

	&.line tr {
    border-bottom: 1px solid rgba(#000, .12);
	}
}

$font: "Roboto", san-serif;

.tabs {
  > .tabs-header {
    overflow: auto;
    white-space: nowrap;
    position: relative;
    &::-webkit-scrollbar {
      width: 4px;
      height: 0px;
    }
    > a {
      display: inline-block;
      text-align: center;
      padding: 14px;
      font-weight: 500;
      font-size: 14px;
      text-decoration: none;
      text-transform: uppercase;
      cursor: pointer;
      font-family: $font;
      &:hover {
        background-color: #efefef;
      }
    }
    > .border {
      width: auto;
      height: 2px;
      background: #222222;
      position: absolute;
      bottom: 0;
      left: 0;
      transition: 0.3s ease;
      z-index: 1;
    }
  }
  > .tabs-content {
    overflow: hidden;
    > .tab {
      display: none;
      &.active {
        display: block;
      }
    }
  }
}

@import "_variables.scss";


$font: "Roboto", san-serif;

[tip] {
  position: relative;
  &:hover{
    &::before,
    &::after {
      display: block;
    }
  }

    &::before {
      content: "";
      position: absolute;
      top: -6px;
      left: 50%;
      transform: translateX(-50%);
      border-width: 5px;
      border-style: solid;
      border-color: #838383 transparent transparent transparent;
      z-index: 100;
      display: none;
    }

  &::after {
    content: attr(tip);
    position: absolute;
    left: 50%;
    top: -6px;
    transform: translateX(-50%) translateY(-100%);
    background: #838383;
    text-align: center;
    text-align: center;
    color: #fff;
    padding: 6px;
    text-transform: uppercase;
    font-family: $font;
    font-size: 10px;
    border-radius: 5px;
    pointer-events: none;
   display: none;
    z-index: 2;
  }
  &.tip-down{
    &::before {
      bottom: -8px;
      top: auto;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent #838383 transparent;
    }
    &::after{
      top: 120%;
      transform: translateX(-50%) translateY(0%);
    }
  }
  &.tip-left{
    &::before {
      bottom: -8px;
      top: auto;
      border-width: 4px 6px 4px 6px;
      border-style: solid;
      border-color: transparent transparent red transparent;
    }
    &::after{
      top: 70%;
      left: auto;
      right: 30%;
      transform: translateX(-50%) translateY(-80%);
    }
  }
  &.tip-right{
    &::before {
      top: 38%;
      left: auto;
      right: -18px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent #838383 transparent transparent;
    }
    &::after{
      top: 70%;
      left: auto;
      right: -95px;
      transform: translateX(-50%) translateY(-80%);
    }
  }
}

  /*For not supported tags*/
label{
    &.tip-left, &.tip-right{
    &::after{
        margin-top: 5%;
      }
    }
  }

$font: 'Roboto', sans-serif;
$light: 300; $regular: 500; $medium: 400;
$typography: (
    ("h-1", -1.5, $light, 96, capitalize),
    ("h-2", -0.5, $light, 60, capitalize),
    ("h-3", 0, $medium, 48, capitalize),
    ("h-4", 0.25, $medium, 34, capitalize),
    ("h-5", 0, $medium, 24, capitalize),
    ("h-6", 0.15, $regular, 20, capitalize),
    ("subtitle-1", 0.15, $regular, 16, capitalize),
    ("subtitle-2", 0.1, $medium, 14, capitalize),
    ("body-1", 0.5, $regular, 16, capitalize),
    ("body-2", 0.25, $regular, 14, capitalize),
    ("btn-txt", 1.25, $regular, 14, uppercase),
    ("caption", 0.4, $regular, 12, capitalize),
    ("overline", 0.5, $regular, 10, uppercase),
);

@each $t in $typography{
  .#{nth($t,1)}{
    font-family: $font;
    letter-spacing: #{nth($t,2)}px;
    font-weight: #{nth($t,3)};
    font-size: #{nth($t,4)}px;
    text-transform: #{nth($t,5)};
  }
}

@import "_mixins.scss";
@import "_variables.scss";


.wave {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  &:after {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform 0.5s, opacity 1s;
  }
  &:active {
    &:after {
      transform: scale(0, 0);
      opacity: 0.3;
      transition: 0s;
    }
  }
}

// $color_vars: "earth", "night", "ocean", "red", "snow", "yellow", "purple", "pink";
// @mixin colorClass($name, $tag) {
//   &.text {
//     &:after {
//       background-image: radial-gradient(
//         circle,
//         color($name) 10%,
//         transparent 10.01%
//       );
//     }
//   }
// }

// @include build-color-class(".wave", $color_vars);

@mixin build-color-class($array) {
  @each $color in $array {
    .#{$color} {
      @include colorClass($color);
    }
  }
}

@mixin keyframes($name) {
  @-moz-keyframes #{$name} {
    @content;
  }
  @-webkit-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@mixin animation($arguments) {
  -webkit-animation: $arguments;
  -moz-animation: $arguments;
  animation: $arguments;
}

@mixin hoverColor($bg, $color) {
  &:hover {
    background: $bg;
    color: $color;
  }
}

@function color($name, $type:null, $val:null) {
  $type-list: map-get($colors, $name);
  $type-list-keys: map-get($type-list, $type);
  @if variable-exists(type) and $type !=null and $type !=base {
    @if map-has-key($type-list, $type) and $val==null {
      @return map-get($type-list-keys, 1);
    } @else {
      @return map-get($type-list-keys, $val);
    }
  } @else {
    @return map-get($type-list, base);
  }
}

@mixin shadow-build($value, $opacity-boost: 0) {
  $param-1-value: map-get($shadow-param-1, $value);
  $param-2-value: map-get($shadow-param-2, $value);
  $param-3-value: map-get($shadow-param-3, $value);

  $param-1-color: rgba(#000, $shadow-param-1-opacity + $opacity-boost);
  $param-2-color: rgba(#000, $shadow-param-2-opacity + $opacity-boost);
  $param-3-color: rgba(#000, $shadow-param-3-opacity + $opacity-boost);

  box-shadow: #{"#{$param-1-value} #{$param-1-color}"},
    #{"#{$param-2-value} #{$param-2-color}"}, #{$param-3-value} $param-3-color;
}

@function shadow-transition-value(
$duration: $shadow-transition-duration,
$easing: $shadow-transition-timing-function) {
  @return #{$shadow-property} #{$duration} #{$easing};
}

$colors: (
  red: (
    base: #F44336,
    light: (
      1: #EF5350,
      2: #E57373,
      3: #EF9A9A,
      4: #FFCDD2,
      5: #FFEBEE
    ),
    dark: (
      1: #E53935,
      2: #D32F2F,
      3: #C62828,
      4: #B71C1C
    )
  ),
  pink: (
    base: #E91E63,
    light: (
      1: #EC407A,
      2: #F06292,
      3: #F48FB1,
      4: #F8BBD0,
      5: #FCE4EC
    ),
    dark: (
      1: #D81B60,
      2: #C2185B,
      3: #AD1457,
      4: #880E4F
    )
  ),
  purple: (
    base: #9C27B0,
    light: (
      1: #AB47BC,
      2: #BA68C8,
      3: #CE93D8,
      4: #E1BEE7,
      5: #F3E5F5
    ),
    dark: (
      1: #8E24AA,
      2: #7B1FA2,
      3: #6A1B9A,
      4: #4A148C
    )
  ),
  deep-purple: (
    base: #673AB7,
    light: (
      1: #7E57C2,
      2: #9575CD,
      3: #B39DDB,
      4: #D1C4E9,
      5: #EDE7F6
    ),
    dark: (
      1: #5E35B1,
      2: #512DA8,
      3: #4527A0,
      4: #311B92
    )
  ),
  indigo: (
    base: #3F51B5,
    light: (
      1: #5C6BC0,
      2: #7986CB,
      3: #9FA8DA,
      4: #C5CAE9,
      5: #E8EAF6
    ),
    dark: (
      1: #3949AB,
      2: #303F9F,
      3: #283593,
      4: #1A237E
    )
  ),
  blue: (
    base: #2196F3,
    light: (
      1: #42A5F5,
      2: #64B5F6,
      3: #90CAF9,
      4: #BBDEFB,
      5: #E3F2FD
    ),
    dark: (
      1: #1E88E5,
      2: #1976D2,
      3: #1565C0,
      4: #0D47A1
    )
  ),
  light-blue: (
    base: #03A9F4,
    light: (
      1: #29B6F6,
      2: #4FC3F7,
      3: #81D4FA,
      4: #B3E5FC,
      5: #E1F5FE
    ),
    dark: (
      1: #039BE5,
      2: #0288D1,
      3: #0277BD,
      4: #01579B
    )
  ),
  cyan: (
    base: #00BCD4,
    light: (
      1: #26C6DA,
      2: #4DD0E1,
      3: #80DEEA,
      4: #B2EBF2,
      5: #E0F7FA
    ),
    dark: (
      1: #00ACC1,
      2: #0097A7,
      3: #00838F,
      4: #006064
    )
  ),
  teal: (
    base: #009688,
    light: (
      1: #26A69A,
      2: #4DB6AC,
      3: #80CBC4,
      4: #B2DFDB,
      5: #E0F2F1
    ),
    dark: (
      1: #00897B,
      2: #00796B,
      3: #00695C,
      4: #004D40
    )
  ),
  green: (
    base: #4CAF50,
    light: (
      1: #66BB6A,
      2: #81C784,
      3: #A5D6A7,
      4: #C8E6C9,
      5: #E8F5E9
    ),
    dark: (
      1: #43A047,
      2: #388E3C,
      3: #2E7D32,
      4: #1B5E20
    )
  ),
  light-green: (
    base: #8BC34A,
    light: (
      1: #9CCC65,
      2: #AED581,
      3: #C5E1A5,
      4: #DCEDC8,
      5: #F1F8E9
    ),
    dark: (
      1: #7CB342,
      2: #689F38,
      3: #558B2F,
      4: #33691E
    )
  ),
  lime: (
    base: #CDDC39,
    light: (
      1: #D4E157,
      2: #DCE775,
      3: #E6EE9C,
      4: #F0F4C3,
      5: #F9FBE7
    ),
    dark: (
      1: #C0CA33,
      2: #AFB42B,
      3: #9E9D24,
      4: #827717
    )
  ),
  yellow: (
    base: #FFEB3B,
    light: (
      1: #FFEE58,
      2: #FFF176,
      3: #FFF59D,
      4: #FFF9C4,
      5: #FFFDE7
    ),
    dark: (
      1: #FDD835,
      2: #FBC02D,
      3: #F9A825,
      4: #F57F17
    )
  ),
  amber: (
    base: #FFC107,
    light: (
      1: #FFCA28,
      2: #FFD54F,
      3: #FFE082,
      4: #FFECB3,
      5: #FFF8E1
    ),
    dark: (
      1: #FFB300,
      2: #FFA000,
      3: #FF8F00,
      4: #FF6F00
    )
  ),
  orange: (
    base: #FF9800,
    light: (
      1: #FFA726,
      2: #FFB74D,
      3: #FFCC80,
      4: #FFE0B2,
      5: #FFF3E0
    ),
    dark: (
      1: #FB8C00,
      2: #F57C00,
      3: #EF6C00,
      4: #E65100
    )
  ),
  deep-orange: (
    base: #FF5722,
    light: (
      1: #FF7043,
      2: #FF8A65,
      3: #FFAB91,
      4: #FFCCBC,
      5: #FBE9E7
    ),
    dark: (
      1: #F4511E,
      2: #E64A19,
      3: #D84315,
      4: #BF360C
    )
  ),
  brown: (
    base: #795548,
    light: (
      1: #8D6E63,
      2: #A1887F,
      3: #BCAAA4,
      4: #D7CCC8,
      5: #EFEBE9
    ),
    dark: (
      1: #6D4C41,
      2: #5D4037,
      3: #4E342E,
      4: #3E2723
    )
  ),
  grey: (
    base: #9E9E9E,
    light: (
      1: #BDBDBD,
      2: #E0E0E0,
      3: #EEEEEE,
      4: #F5F5F5,
      5: #FAFAFA
    ),
    dark: (
      1: #757575,
      2: #616161,
      3: #424242,
      4: #212121
    )
  ),
  blue-grey: (
    base: #607D8B,
    light: (
      1: #78909C,
      2: #90A4AE,
      3: #B0BEC5,
      4: #CFD8DC,
      5: #ECEFF1
    ),
    dark: (
      1: #546E7A,
      2: #455A64,
      3: #37474F,
      4: #263238
    )
  ),
  black: (
    base: #000000
  ),
  white: (
    base: #FFFFFF
  ),
);

$font: "Roboto", san-serif;
