@mixin build-color-class($array) {
  @each $color in $array {
    .#{$color} {
      @include colorClass($color);
    }
  }
}

@mixin keyframes($name) {
  @-moz-keyframes #{$name} {
    @content;
  }
  @-webkit-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@mixin animation($arguments) {
  -webkit-animation: $arguments;
  -moz-animation: $arguments;
  animation: $arguments;
}

@mixin hoverColor($bg, $color) {
  &:hover {
    background: $bg;
    color: $color;
  }
}

@function color($name, $type:null, $val:null) {
  $type-list: map-get($colors, $name);
  $type-list-keys: map-get($type-list, $type);
  @if variable-exists(type) and $type !=null and $type !=base {
    @if map-has-key($type-list, $type) and $val==null {
      @return map-get($type-list-keys, 1);
    } @else {
      @return map-get($type-list-keys, $val);
    }
  } @else {
    @return map-get($type-list, base);
  }
}

@mixin shadow-build($value, $opacity-boost: 0) {
  $param-1-value: map-get($shadow-param-1, $value);
  $param-2-value: map-get($shadow-param-2, $value);
  $param-3-value: map-get($shadow-param-3, $value);

  $param-1-color: rgba(#000, $shadow-param-1-opacity + $opacity-boost);
  $param-2-color: rgba(#000, $shadow-param-2-opacity + $opacity-boost);
  $param-3-color: rgba(#000, $shadow-param-3-opacity + $opacity-boost);

  box-shadow: #{"#{$param-1-value} #{$param-1-color}"},
    #{"#{$param-2-value} #{$param-2-color}"}, #{$param-3-value} $param-3-color;
}

@function shadow-transition-value(
$duration: $shadow-transition-duration,
$easing: $shadow-transition-timing-function) {
  @return #{$shadow-property} #{$duration} #{$easing};
}
